import React from 'react'
import { Link } from 'gatsby'

import facebook from '../img/social/facebook.svg'
import admin from '../img/pencil.svg'
import logo from '../../static/img/White Logo.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="social">
          {/* Google Fonts Import */}
          <div style={{display: 'flex', marginTop: '-25px', marginBottom: '20px', alignItems: 'center'}}>
            <link
              href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
              rel="stylesheet"
            ></link>
            <img
              src={logo}
              alt="RBBC Logo"
              style={{
                width: '3em',
                height: '3em',
                lineHeight: 'none',
                display: 'inline-flex',
                marginLeft: 'auto',
              }}
            />
            <h1 style={{
              fontSize: '1.7em',
              color: '#dfdfdf',
              fontFamily: 'IM Fell English',
              display: 'inline-flex',
              marginRight: 'auto',
              marginTop: '8px',
            }}>
              Red Bank Baptist Church
            </h1>
          </div>
          <a title="Facebook" href="https://www.facebook.com/RedBankMarionville">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
          {/* href={window.location.href + "admin/" */}
          <a title="Admin" href={"https://redbankbaptist.net/admin"}>
            <img
              src={admin}
              alt="Admin"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
        </div>
        <div className="content has-text-centered" style={{ paddingBottom: '30px'}}>
          <p id='copyright'>
            {'Copyright © ' + new Date().getFullYear() + ' Red Bank Baptist Church / All rights reserved / Privacy Policy'}
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
